<!--
 * @Author: chenyc -> zhangkai
 * @Date: 2021-04-01 22:06:45
 * @LastEditTime: 2021-11-23 10:18:04
 * @LastEditors: zhangkai
-->
<template>
  <div class="geely-height-top">
    <div class="geely-card">
        <!-- 名片盒子模型 -->
        <div class="geely-bag" :style="{'background-image': 'url('+backgroundImage+')'}">
        <!-- 信息模块 -->
        <div class="geely-box">
            <!-- 头像changeAvatar -->
            <div class="geely-portrait">
            <van-image width="80" height="80" radius='40'  :src="userInfo.avatar"></van-image>
            </div>
            <!-- 名字和职位 -->
            <div class="geely-content">
            <div class="name">{{userInfo.name}}</div>
            <div class="position">{{userInfo.dealerShortName}}{{userInfo.dealerShortName && userInfo.position?',':''}}{{userInfo.position}}</div>
            </div>
            <!-- 联系方式 -->
            <div class="geely-contact">
            <div class="geely-contact-lt" @click="openCallPhone('',userInfo.telephone)">
                <div class="geely-lt-phone">
                <div class="phone">电话</div>
                <div class="number">{{userInfo.telephone}}</div>
                </div>
                <div class="geely-contact-icon">
                <i class="iconfont icondianhua"/>
                </div>
            </div>
            <div class="border"></div>
            <div class="geely-contact-rt" @click="openCallPhone('',userInfo.mobile)">
                <div class="geely-rt-phone">
                <div class="phone">手机</div>
                <div class="number">{{userInfo.mobile}}</div>
                </div>
                <div class="geely-contact-icon">
                <i class="iconfont iconshoujitep"/>
                </div>
            </div>
            </div>
            <!-- 门店地址 -->
            <div class="geely-address">
            <div class="geely-address-lt">
                <div class="address-title">门店地址</div>
                <div class="address-data">{{userInfo.address}}</div>
            </div>
            <!-- <div class="geely-address-rt">
                <i class="iconfont icondizhi1"/>
            </div> -->
            </div>
            <!-- 二维码 -->
            <div class="geely-qrcode">
            <div class="geely-qrcode-img">
                <div class="qr-code">
                <img :src="userInfo.qrCode" alt="">
                </div>
            </div>
            <div class="geely-qrcode-title">扫码添加您的专属顾问</div>
            </div>
        </div>
        </div>
    </div>

    <!-- 分享功能 -->
    <div class="shareBtn">
      <div class="share-box enterprise" @click="sendChatMessage('qwChat')">
        <div class="share-box-icon">
          <img src="https://scrm-prd-1301971413.cos.ap-shanghai.myqcloud.com/scrm/qiwei.png" />
        </div>
        <p class="share-box-title">分享到企微</p>
      </div>
      <!-- <div v-if="isInWechat" class='share-box' @click="sendChatMessage('weChat')">
        <div class="share-box-icon">
          <img src="https://scrm-prd-1301971413.cos.ap-shanghai.myqcloud.com/scrm/weixin.png" />
        </div>
        <p class="share-box-title">分享到微信</p>
      </div>
      <div v-if="!isChatEntry" class='share-box' @click="switchShareOverlay">
        <div class="share-box-icon">
          <img src="https://scrm-prd-1301971413.cos.ap-shanghai.myqcloud.com/scrm/pengyouquan.png" />
        </div>
        <p class="share-box-title">分享到朋友圈</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getUrlParams } from '@/utils/utils'
import { getEmpCard } from '@/api/modules/businessCard'
// import { mapGetters } from 'vuex'
import mixin from '../mixin'
import { Notify } from 'vant'
export default {
  name: '',
  components: {},
  mixins: [mixin],
  data() {
    return {
      backgroundImage: require('@/assets/img/businessCard/bg_1.png'),
      userInfo: {
        avatar: require('@/assets/img/businessCard/qw_1.png'),
        name: '张三',
        dealerShortName: '厂端部门',
        hotPhone: '400-200-300',
        mobile: '1888888888',
        address: '中国浙江省杭州市滨江区门店地址188号'
      },
      shareHref: {}
    }
  },
  computed: {
    // 分享信息
    shareInfo() {
      return {
        title: '我的名片', // 分享标题
        link: `${window.location.origin}/businessCard/share?userId=${this.shareHref.userId}`, // 分享链接
        desc: `您好，我是${this.userInfo.dealerShortName}${this.userInfo.name}，很高兴为您服务。`, // 分享描述
        imgUrl: this.userInfo.avatar // 分享图标
      }
    }
    // ,
    // ...mapGetters({
    //   userInfo: 'userAuth/getAuthorizeInfo'
    // })
  },
  watch: {
    // getUserInfo: {
    //   handler(userinfo) {
    //     this.userInfo = userinfo
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  mounted() {

  },
  created() {
    this.shareHref = getUrlParams(window.location.href).params
    console.log('this.shareHref', this.shareHref)
    this.getBusinessEmpCard()
  },

  methods: {
    getBusinessEmpCard() {
      const params = {
        userId: this.shareHref.userId
      }
      getEmpCard(params).then(res => {
        if (res.code === 0) {
          this.userInfo = res.data
          // 处理多个部门
          if (res.data.organList.length > 0) {
            res.data.organList.forEach((e, idx) => {
              this.userInfo.dealerShortName = (idx === 0 ? e.deptName : ('/' + e.deptName))
            })
          }
        }
      })
    },
    // 通过聊天工具栏向当前会话发送消息
    sendChatMessage(type) {
      // 积分触点事件
      const over = (res) => {
        if (res.err_msg === 'sendChatMessage:ok') {
          Notify({ type: 'success', message: '分享成功' })
        }
        if (res.err_msg === 'shareAppMessage:ok') {
          Notify({ type: 'success', message: '分享成功' })
        }
        if (res.err_msg === 'shareWechatMessage:ok') {
          Notify({ type: 'success', message: '分享成功' })
        }
        // 分享到朋友圈
        if (res.err_msg === 'onMenuShareTimeline:ok') {
          Notify({ type: 'success', message: '分享成功' })
        }
        if (res.err_msg === 'sendChatMessage:cancel') {
          Notify({ type: 'warning', message: '取消分享' })
        }
      }
      if (type === 'weChat') {
        // 自定义转发到微信
        wx.invoke('shareWechatMessage', this.shareInfo, over)
      }
      else if (type === 'pengyouquan') {
        wx.invoke('onMenuShareTimeline', this.shareInfo, over)
      }
      else {
        // 自定义转发到会话
        wx.invoke('shareAppMessage', this.shareInfo, over)
        // 将H5页面通过个人群发发送给客户
        // wx.invoke('shareToExternalContact', this.shareInfo, over)
        // 将H5页面通过群发助手发送给客户群
        // wx.invoke('shareToExternalChat', this.shareInfo, over)
      }
    }
  }
}

</script>
<style lang='less' scoped>
.shareBtn{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 30px;
  .share-box{
    width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .share-box-icon{
        vertical-align: middle;
        width: 32px;
        height: 32px;
        img{
          width: 100%;
          height: 100%;
        }
    }
    .share-box-title{
      font-size: 12px;
      margin-top: 4px;
      color: #333333;
    }
  }
}
.geely-height-top{
  padding-top: 36px;
  .abcd{
    width: 100%;
    height: 100vh;
    background: red;
    position: fixed;
    z-index: 99;
  }
}
@deep: ~">>>";
@textColor: #FFFFFF;
.geely-card{
  width: 100%;
  height: 480px;
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;
  .geely-bag{
    width: 100%;
    height: 100%;
    // background-image: url('/assets/img/businessCard/bg_1.png');
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    box-shadow: 0 0 0 #003DFF;
    .geely-box{
      position: absolute;
      top: -16px;
      left: 50%;
      transform: translate(-50%, 0);
      .geely-portrait{
         display: flex;
         justify-content: center;
         align-items: center;
      }
      .geely-content{
        margin-top: 16px;
        text-align: center;
        .name{
          font-size: 20px;
          font-weight: 500;
          color: @textColor;
        }
        .position{
          font-weight: 400;
          font-size: 14px;
          color: #cdd8ff;
          margin-top: 4px;
        }
      }
      .geely-contact{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 8px;
        margin-top: 29px;
        border-bottom: 1px solid #F0F0F0;
        .geely-contact-lt{
          width: 141px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .geely-lt-phone{
            .phone{
              font-size: 12px;
              color: #cdd8ff;
            }
            .number{
              margin-top: 1px;
              font-size: 13px;
              color: @textColor;
            }
          }
         @{deep} .geely-contact-icon{
            margin-right: 20px;
            .iconfont{
              font-size: 24px;
              color: @textColor;
            }
          }
        }
        .border{
          width: 1px;
          height: 20px;
          border: 1px solid @textColor;
          background: #FFFFFF;
          transform: scaleX(0.5);  //缩放宽度，达到0.5px的效果
        }
        .geely-contact-rt{
          width: 141px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .geely-rt-phone{
            margin-left: 20px;
            .phone{
              font-size: 12px;
              color: #cdd8ff;
            }
            .number{
              margin-top: 1px;
              font-size: 13px;
              color: @textColor;
            }
          }
         @{deep} .geely-contact-icon{
            .iconfont{
              font-size: 24px;
              color: @textColor;
            }
          }
        }
      }
      .geely-address{
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        .geely-address-lt{
          flex: 1;
          padding-right: 10px;
          .address-title{
            font-size: 12px;
            color: #cdd8ff;
          }
          .address-data{
            width: 259px;
            font-size: 13px;
            color: @textColor;
            margin-top: 1px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
         @{deep} .geely-address-rt{
           display: flex;
           align-items: center;
            .iconfont{
              font-size: 24px;
              color: @textColor;
            }
        }
      }
      .geely-qrcode{
        margin-top: 26px;
        text-align: center;
        text-align: -webkit-center;
        .geely-qrcode-img{
          width: 118px;
          height: 132px;
          position: relative;
          img{
            width: 100%;
            height: 100%;
          }
          @{deep} .qr-code{
             width: 108px;
             height: 108px;
             position: absolute;
             top: 5px;
             left: 50%;
             transform: translate(-50%, 0);
             img{
               width: 100%;
               height: 100%;
             }
          }
        }
        .geely-qrcode-title{
          margin-top: 4px;
          font-size: 12px;
          color: #cdd8ff;
        }
      }
    }
  }
}
</style>
